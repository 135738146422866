import CryptoJS, {AES} from "crypto-js"
const secretKey="Star2023#@!";


const encryptData=(str)=>{
    return AES.encrypt(str,secretKey).toString();
}

const decryptData=(str)=>{
    if(str=="" || str===undefined || str==null){
        return str
    }
    let checkenc = localStorage.getItem("user");
    if( checkenc){
        if(checkenc.includes("success")){
            return checkenc
        }
        else{
            try{
                const data = AES.decrypt(str,secretKey).toString(CryptoJS.enc.Utf8);
                return data?data:"{}"
            }
            catch (error) {
                return "{}"
            }
        }
    }
    else{
        try{
            const data = AES.decrypt(str,secretKey).toString(CryptoJS.enc.Utf8);
            return data
            }
            catch (error) {
                console.log(error)
                return "{}"
            }
    }
}

const validateString=(value,validationType)=>{
    let language = localStorage.getItem('i18nextLng')
    var regExp;
    if(language=='English'){
        regExp=/[^a-zA-Z0-9., ]/
        if(validationType=='string'){
            regExp=/[^a-zA-Z ]/
        }
        else if(validationType=='number'){
            regExp = /^([0-9]+)$/;
        }
    }
    else{
        regExp = /[!@#$%^&*~`()_+\-=\[\]{};':"\\|<>\/]/;
        if(validationType=='string'){
            regExp = /[!@#$%^&*`~()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/;
        }
        else if(validationType=='number'){
            regExp = /^([0-9]+)$/;
        }
    }
    
    return value!=""?!regExp.test(value):true
}

export {encryptData, decryptData, validateString}