import React, { useState, useEffect } from 'react';
import { gapi, loadAuth2 } from 'gapi-script'
import {config} from "../_config"
import facebook from "../assets/images/icons/facebook.png";
import apple from "../assets/images/icons/appleIcon.png";
import google from "../assets/images/icons/google.png";
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { history } from '../_helpers';
import { ClipLoader } from 'react-spinners';
import AppleSignin from 'react-apple-signin-auth'
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";

const SocialSignUpUS = (props) => {
    const {SocialSignIn} = props
    const applicationTypeId = config.applicationTypeId;
    // const cookies = new Cookies();
    const isOldUser = localStorage.getItem('isOldUser')
    const [userdata, setUser] = useState(null);
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {            
        const setAuth2 = async () => {
        const auth2 = await loadAuth2(gapi, config.GoogleClientId, '')
        if (auth2.isSignedIn.get()) {
            signOut()
        } else {
            attachSignin(document.getElementById('customBtn'), auth2);
        }
        }
        setAuth2();
    }, []);

    useEffect(() => {
        if (!userdata) {            
            setAuth3();
        }
    }, [userdata])
    const setAuth3 = async () => {
        const auth2 = await loadAuth2(gapi, config.GoogleClientId, '')
        attachSignin(document.getElementById('customBtn'), auth2);
    }

    const updateUser = (currentUser) => {
        const firstName = currentUser.getBasicProfile().rV;
        const lastName = currentUser.getBasicProfile().uT;
        const email = currentUser.getBasicProfile().getEmail();
        const authId = currentUser.getBasicProfile().NT
        const user = {
            Name: firstName,
            lastName: lastName,
            Email: email,
            isSocialMedia: true,
            socialType:'Google',
            authId:authId,
            requireToVerifyEmail: false
        };
        SocialSignIn(user)
    };

    const attachSignin = (element, auth2) => {
        auth2.attachClickHandler(element, {},
        (googleUser) => {            
            setisLoading(true)
            updateUser(googleUser);
        }, (error) => {
            console.log(JSON.stringify(error))
        });
    };

    const signOut = () => {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(() => {
        setUser(null);
        console.log('User signed out.');
        });
    }

    const responseFacebook = (data) => {

        setisLoading(true);
        const name = data.name.split(' ')    
        const firstName = name[0];
        const lastName = name[1];
        const email = data.email;
        const authId = data.userID;
        const user = {
            Name: firstName,
            lastName: lastName,
            Email: email,
            isSocialMedia: true,
            socialType:'Facebook',
            authId:authId,
            requireToVerifyEmail: email?false:true
        }
        SocialSignIn(user)
    }

    const responseApple = (response) => {
        
        setisLoading(true);
        const data = jwt_decode(response.authorization.id_token);
        const firstName = '';
        const lastName = '';
        const email = data.email;
        const authId = data.sub;
        const user = {
            Name: firstName,
            lastName: lastName,
            Email: email,
            isSocialMedia: true,
            socialType:'Apple',
            authId:authId,
            requireToVerifyEmail: email?false:true
        }
        SocialSignIn(user)
    }

    const responseErrorApple = (error) =>{
        console.log(error)
    }




    return (
        <div className='container'>
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center p-0">
                    <div className="col-12 p-0">
                        <div className="social_separator">
                            <span>Or</span>
                        </div>
                        <ul className='p-0 socailmedia'>
                            <li>
                                <FacebookLogin
                                appId={config.FacebookAppId}
                                fields="name,email"
                                callback={responseFacebook}
                                render={renderProps => (
                                        <a  onClick={renderProps.onClick} className="btn w-100 fb-btn d-flex mt-1" ><img className='mr-1' src={facebook} width="30" height="30" alt="Facebook"/><span className='ml-2'>{i18next.t('Continue with Facebook')}</span>
                                        </a>
                                )}
                                redirectUri={config.baseURL+"/NewSignUp"}
                                disableMobileRedirect={true}
                                />
                                
                            </li>
                            <li>
                            <AppleSignin
                                /** Auth options passed to AppleID.auth.init() */
                                authOptions={{                                        
                                clientId: config.AppleClientId,
                                scope: 'email name',
                                redirectURI: config.baseURL+"/NewSignUp",                                        
                                state: 'state',
                                nonce: 'nonce',
                                usePopup: true,
                                }}
                                className="apple-auth-btn"
                                /** Removes default style tag */
                                noDefaultStyle={false}
                                buttonExtraChildren="Continue with Apple"
                                onSuccess={responseApple}
                                onError={responseErrorApple}
                                skipScript={false} 
                                render={(props) => <a {...props} className="btn w-100 text-start fb-btn text-left d-flex" ><img className='mr-1' src={apple} width="30" height="30" alt="Apple" /><span className='ml-2'>{i18next.t('Continue with Apple')}</span> 
                                </a>}
                            />
                                
                            </li>
                            <li>
                                <a id='customBtn'  className="btn w-100 text-start fb-btn text-left d-flex" ><img className='mr-1'src={google} width="30" height="30" alt="Google"/><span className='ml-2'>{i18next.t('Continue with Google')}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialSignUpUS
