import {
  userConstants
} from "../_constants";
import {
  userService
} from "../_services";
import {
  alertActions
} from "./";
import {
  history
} from "../_helpers";
import {
  pendingpaymentService
} from "../_services";
import {
  purchaseHistoryService
} from "../_services";
import i18next from "i18next";
import { config } from "../_config";
import { decryptData, encryptData } from "../_helpers/encryptData";

export const userActions = {
  login,
  register,
  update,
  logout,
};

function login(username, password, rememberme, thirdPartyQR) {
  return (dispatch) => {
    dispatch(
      request({
        username,
      })
    );

    //here pending logic
    userService.login(username, password, rememberme, thirdPartyQR).then(
      async (user) => {
        let authUser = user.authToken;
        let CustomerName = user.firstName+" "+user.lastName;
        let Iduser = user.userId;
        let CardToken = user.CeiloToken;
        let applicationTypeId = config.applicationTypeId
        let macAddress= decryptData(localStorage.getItem('qrcode'))
        let authToken= user.authToken
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
                },
                body: new URLSearchParams({
                  authToken,
                  applicationTypeId,
                  macAddress
                })
              };
              await fetch(
                `${config.envUrl}VendingAppApi/getConsumerOfferDetails`,
                requestOptions
              )
              .then(function (response) {
                return response.json();
            })
            .then(function(body){  
              localStorage.setItem('reward',encryptData(JSON.stringify(body)))
            })
            let PurchaseRequestOptions = {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/x-www-form-urlencoded'
                  },
                  body: new URLSearchParams({authToken,applicationTypeId,macAddress})
              }
              let that = this;
              await fetch(`${config.envUrl}VendingAppApi/getPurchaseOfferQty`, PurchaseRequestOptions)
              .then(function (response) {
                  return response.json();
              })
              .then((result) => {
                  localStorage.setItem('isRewardAvailable',encryptData(JSON.stringify([JSON.stringify(result.records)])))
                  // localStorage.setItem('isB0G1Fenabled',result.records && result.records.length>0?decryptData('true'):decryptData('false'))
              })
        pendingpaymentService
          .getPendingPayment({
            authUser,
            Iduser,
          }) 
          .then((pending) => {
            let newuser = sessionStorage.getItem('newuser');
            if (pending.success && pending.message === "No Records Found") {
              //no pending amount
              
              if (thirdPartyQR) {
                //insert first time user logic here, right now based on purchase history
                var purchaseobj = {
                  authToken: authUser,
                  userId: Iduser,
                  pageNumber:1,
                  limit:10
                };
                purchaseHistoryService
                  .getPurchaseHistory(purchaseobj)
                  .then((products) => {
                    if (
                      products &&
                      products.data &&
                      products.data.length != 0
                    ) {
                      dispatch(success(user));
                      history.push("/Home");
                    }else if(newuser=='true'){
                      dispatch(success(user));
                      history.push("/welcome");
                    }else {
                      dispatch(success(user));
                      history.push("/ScanButton"); // show tutorial on login
                      // localStorage.setItem("showtutorial", true);
                    }
                  });
              } else {
                //insert first time user logic here, right now based on purchase history
                var purchaseobj = {
                  authToken: authUser,
                  userId: Iduser,
                  pageNumber:1,
                  limit:10
                };
                purchaseHistoryService
                  .getPurchaseHistory(purchaseobj)
                  .then((products) => {
                    dispatch(success(user));
                    if(newuser=='true'){
                      history.push("/welcome");
                    }
                    else{
                      history.push("/Scanbutton");
                    }
                    
                  });
              }
            } else if (!pending.success) {
              alert("Check your Internet Connection and try again");
            } else {  
              // dispatch(logout());            
              // history.push({
              //   pathname: "/PendingAmount",
              //   state: {
              //     pendingAmount: pending.data.amount,
              //     authtoken: authUser,
              //     purchaseNumber: pending.data.purchaseNumber,
              //     userId: Iduser,
              //     CustomerName:CustomerName,
              //     CardToken:CardToken
              //   },
              // });
              // localStorage.setItem('Pending',JSON.stringify(pending));
              // localStorage.removeItem("user"); //so that user cant login
              dispatch(success(user));
              // if(newuser=='true'){
              //   history.push("/welcome");
              // }
              if(thirdPartyQR){
                history.push("/Home");
              }
              else{
                history.push("/ScanButton");
              }
              
            }
          });
      },
      (error) => {
        console.log(error)
        // error = (localStorage.getItem("i18nextLng") == "ja" || navigator.language == "ja") &&
        //   error.includes("There is no user registered") ? i18next.t("NoUser") :
        //   (localStorage.getItem("i18nextLng") == "ja" || navigator.language == "ja") &&
        //   error.includes("Invalid username/password") ? i18next.t("InvalidUserNamePwd") : i18next.t("InvalidPassword");
        if (error == "TypeError: Failed to fetch") {
          error = i18next.t("ConnectionIssue")
        }
        if(error===1523){
          error = i18next.t("NoUserFound")
        }
        else if(error===1520){
          error = i18next.t("InvalidPassword")
        }
        else if(error===1522){
          error=i18next.t("ActivationLink")
        }
        else if(error===503){
          error=i18next.t("CommonError")
        }
        else if(error===15487){
          error = i18next.t("Oops! It looks like you used this email to log in with social media. Please log in with your social media account to continue")
        }
        else{
          error = i18next.t("CommunicationError")
        }
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return {
      type: userConstants.LOGIN_REQUEST,
      user,
    };
  }

  function success(user) {
    return {
      type: userConstants.LOGIN_SUCCESS,
      user,
    };
  }

  function logout(){
    return {
      type: userConstants.LOGOUT,
    };
  }

  function failure(error) {
    return {
      type: userConstants.LOGIN_FAILURE,
      error,
    };
  }
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(user).then(
      (user) => {
        dispatch(success());
        dispatch(
          alertActions.success("success")
        );
      },
      (error) => {
        if (error == 'TypeError: Failed to fetch') {
          error = i18next.t("ConnectionIssue")
        }
        else if(error===1527){
          error = i18next.t("UserExist")
        }
        else if(error===15487){
          error = i18next.t("Oops! It looks like you used this email to log in with social media. Please log in with your social media account to continue")
        }
        else if(error===15503){
          error=i18next.t("Por favor, insira um CPF válido");
        }
        else if(error===15505){
          error=i18next.t("A data de nascimento informada não coincide com a do portador do CPF informado");
        }
        else if(error===15504){
          error=i18next.t("Por favor, insira um CPF válido");
        }
        else if(error===15519){
          error=i18next.t("ValidateMsg_ForInvalidDoB");
        }
        else if(error===155250){
          error=i18next.t("Flood_Registration");
        }
        else{
          error = i18next.t("CommunicationError")
        }
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return {
      type: userConstants.REGISTER_REQUEST,
      user,
    };
  }

  function success(user) {
    return {
      type: userConstants.REGISTER_SUCCESS,
      user,
    };
  }

  function failure(error) {
    return {
      type: userConstants.REGISTER_FAILURE,
      error,
    };
  }
}

function update(user) {
  return (dispatch) => {
    dispatch(
      request({
        user,
      })
    );

    userService.update(user).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        if (error === 503 || error===1) {
          error = i18next.t("CommunicationError")
          console.log(error)
        }
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return {
      type: userConstants.USERUPDATE_REQUEST,
      user,
    };
  }

  function success(user) {
    return {
      type: userConstants.USERUPDATE_SUCCESS,
      user,
    };
  }

  function failure(error) {
    return {
      type: userConstants.USERUPDATE_FAILURE,
      error,
    };
  }
}

function logout() {
  userService.logout();
  return {
    type: userConstants.LOGOUT,
  };
}