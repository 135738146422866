export function getAppId(){
  var id = '';
  let origin = window.location.origin
  if(origin=='https://cokeandgo-qa-us.instagng.com' || origin=='https://cokeandgo-us.instagng.com' || origin=='https://cokeandgo-stg-us.instagng.com'){
    id=8;
  }
  else if(origin=='https://cokeandgo-qa-brazil.instagng.com' || origin=='https://cokeandgo-brazil.instagng.com' || origin=='https://cokeandgo-stg-brazil.instagng.com'){
    id=9;
  }
  else if(origin=='https://cokeandgo-qa-poland.instagng.com' || origin=='https://cokeandgo-poland.instagng.com' || origin=='https://cokeandgo-stg-poland.instagng.com' ){
    id=11;
  }
  else if(origin=='https://cokeandgo-qa-austria.instagng.com' || origin=='https://cokeandgo-austria.instagng.com' ||origin=='https://cokeandgo-stg-austria.instagng.com' ){
    id=12;
  }
  else if(origin=='https://cokeandgo-qa-chile.instagng.com' || origin=='https://cokeandgo-chile.instagng.com' || origin=='https://cokeandgo-stg-chile.instagng.com'){
    id=13;
  }
  else if(origin=='https://cokeandgo-qa-mexico.instagng.com' || origin=='https://cokeandgo-mexico.instagng.com' || origin=='https://cokeandgo-stg-mexico.instagng.com' ){
    id=14;
  }
  else if(origin=='https://cokeandgo-qa-peru.instagng.com' || origin=='https://cokeandgo-peru.instagng.com' || origin=='https://cokeandgo-stg-peru.instagng.com'){
    id=15;
  }
  else if(origin=='https://cokeandgo-qa-colombia.instagng.com' || origin=='https://cokeandgo-colombia.instagng.com' || origin=='https://cokeandgo-stg-colombia.instagng.com'){
    id=16;
  }
  else if(origin=='https://cokeandgo-qa-southafrica.instagng.com' || origin=='https://cokeandgo-southafrica.instagng.com' || origin=='https://cokeandgo-stg-southafrica.instagng.com' ){
    id=17;
  }
  else if(origin=='https://cokeandgo-qa-india.instagng.com' || origin=='https://cokeandgo-india.instagng.com' || origin=='https://cokeandgo-stg-india.instagng.com'){
    id=19;
  }
  else if(origin=='https://cokeandgo-qa-singapore.instagng.com' || origin=='https://cokeandgo-singapore.instagng.com' || origin=='https://cokeandgo-stg-singapore.instagng.com'){
    id=20;
  }
  else if(origin=='https://cokeandgo-qa-hongkong.instagng.com' || origin=='https://cokeandgo-hongkong.instagng.com' ||origin=='https://cokeandgo-stg-hongkong.instagng.com' ){
    id=21;
  }
  else if(origin=='https://cokeandgo-qa-morocco.instagng.com' || origin=='https://cokeandgo-morocco.instagng.com' || origin=='https://cokeandgo-stg-morocco.instagng.com'){
    id=22;
  }
  else if(origin=='https://cokeandgo-qa-taiwan.instagng.com' || origin=='https://cokeandgo-taiwan.instagng.com' || origin=='https://cokeandgo-stg-taiwan.instagng.com'){
    id=23;
  }
  else if(origin=='https://cokeandgo-ireland.instagng.com' || origin=='https://cokeandgo-qa-ireland.instagng.com' || origin=='https://cokeandgo-stg-ireland.instagng.com'){
    id=24;
  }
  else if(origin=='https://cokeandgo-qa-egypt.instagng.com' || origin=='https://cokeandgo-egypt.instagng.com' || origin=='https://cokeandgo-stg-egypt.instagng.com'){
    id=26;
  }
  else if(origin=='https://cokeandgo-qa-uk.instagng.com' || origin=='https://cokeandgo-uk.instagng.com' || origin=='https://cokeandgo-stg-uk.instagng.com'){
    id=31;
  }
  else if(origin=='https://cokeandgo-qa-czechia.instagng.com' || origin=='https://cokeandgo-czechia.instagng.com' || origin=='https://cokeandgo-stg-czechia.instagng.com'){
    id=32;
  }
  else if(origin=='http://localhost:3000' || origin=='https://localhost:3000' || origin=='https://cokeandgo-new.azurewebsites.net'){
    id=8;
  }
  else{
    id=20;
  }
  return id;
};