import i18n from "i18next";
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { config } from "../_config";
import * as moment from 'moment';
import { history } from "../_helpers";
import i18next from "i18next";
import Heading from "../containers/Heading/Heading";
import offerbg from "../_assets/images/icons/reward-bg.png"
import logo from "../_assets/images/icons/logo.png"
import star from "../_assets/images/icons/star-with-bg.png"
import FooterNav from "../components/FooterNav/FooterNav";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";

export default class PointDetails extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | point-detail`;
        CapturePage({hitType:"pageview",page:"/point-detail"})    
        this.state = {
            BuyQty:0,
            PurchaseQty:0,
            isLoading:true,
            showPopup:false,
            errorCode:0,
            errorMsg:'',
            isOffline:false
        }
        let rewardData = JSON.parse(decryptData(localStorage.getItem('reward')));
        if(!rewardData.success){
          history.push('/ScanButton');
        }
        let user = JSON.parse(decryptData(localStorage.getItem('user')));
        let authToken= user.authToken;
        let applicationTypeId= config.applicationTypeId;
        let macAddress= decryptData(localStorage.getItem('qrcode'))
        let RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({authToken,applicationTypeId,macAddress})
        }
        let that = this;
        fetch(`${config.envUrl}VendingAppApi/getPurchaseOfferQty`, RequestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            if(result.success){
                that.setState({
                    isLoading:false,
                    BuyQty:result.records[0].BuyQty,
                    PurchaseQty:result.records[0].PurchaseQty
                })
            }
            else{
                that.setState({
                    showPopup:true,
                    isLoading:false,
                    errorCode:result.code,
                    errorMsg:result.message
                })
            }
        }).catch(function (error) {
            that.setState({
                isLoading:false,
                isOffline:true
            })
        });

        this.closingPopup = this.closingPopup.bind(this);
        this.closingisOfflinePopup = this.closingisOfflinePopup.bind(this);
    }
    closingPopup(){
        if(this.state.errorCode==401){
            history.push('/Logout')
        }
        else{
            this.setState({
                showPopup:false,
                errorCode:0
            })
        }
    }
    closingisOfflinePopup(){
        this.setState({
          isOffline:false
        })
    }
    render() {
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        //   let rewardData = JSON.parse(localStorage.getItem('reward'));
        return (
            <div className="main-wrapper">        
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 p-0" style={{background:`url(${offerbg})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',height:'100vh'}}>
                            <div className="content p-0">
                                <div className="heading text-center">
                                 <p className="title mb-0 text-white">{i18next.t('MenuReward')}</p>
                                </div>
                                <div className="pointdetail">
                                    <img src={logo} height={80} className="ml-3"/>

                                    <div className="reward_title">
                                        <h4>{i18next.t("For every 10 drinks you purchase get one free")}</h4>
                                        {/* <h4 className="float-right text-center" style={{width:'100px'}}>
                                        {this.state.PurchaseQty}/{this.state.BuyQty}<br/>
                                        <span>{i18next.t("Today")}</span>
                                        </h4> */}
                                    </div>
                                    <div className="d-flex px-4 pt-4 pb-0">
                                        <div className="mr-3">
                                            <img src={star} height="35"/>
                                        </div>
                                        <div>
                                            <h5 className="text-white">{i18n.t("You have XX Rewards Points",{points:this.state.PurchaseQty})}</h5>
                                        </div>
                                    </div>
                                    <div className="reward_details" >
                                        <h5 className="text-white">{i18next.t("How it works?")}</h5>
                                        <p className="text-white">{i18next.t('Every purchase from Coke’s Smart Cooler gets you closer to scoring one free iced-cold Coca-Cola product. After you make 10 beverage purchase , click "apply" in the pop-up window to redeem your free beverage during the 11th transaction. Just keep scanning, sipping and swinging by for more.')}</p>
                                    </div>
                                    <div className="see_rules" style={{width:"auto"}}>
                                    <Link to={{pathname:"/policy/RewardSeeRules"}}>{i18next.t("See Rules")}</Link></div>
                                    <FooterNav />
                                </div> 
                            </div>
                            {
                                this.state.isLoading &&
                                <div className="spinner">
                                    <div style={style}>
                                    <ClipLoader size={50} color={"#FF0000"} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>                
            </div>
        )
    }
}
