import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../_assets/images/icons/logo.png";
import Button from "../components/button";
import RegisterForm from "../containers/RegisterForm";
import "../assets/scss/defaults.scss";
import BackData from "../components/BackData/BackData";
import { connect } from "react-redux";
import { userActions,alertActions } from "../_actions";
import CoolerPopup from "../components/Model-Popup/Popup";
import { history } from "../_helpers";
import i18next from "i18next";
import moment from "moment";
import validator from 'validator';
import { ClipLoader } from "react-spinners";
import Heading from "../components/PublicHeader/publicHeader";
import { userService } from "../_services/user.service"
import { config } from "../_config";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { Trans } from "react-i18next";
import { decryptData, encryptData } from "../_helpers/encryptData";
import CountryList from "../components/CountryList/CountryList";
import { v4 as uuidv4 } from "uuid";
class SocialSignUp extends Component {
  constructor(props) {
    super(props);
    let udata = props.location.state
    document.title=`Coke & Go ${config.countryName} | socialsignup`;
    CapturePage({hitType:"pageview",page:"/socialsignup"})
    this.state = {
      isLoading:false,
      isInvalidEmail:false,
      user: {
        firstName: udata?udata.firstName:'',
        lastName: udata?udata.lastName:'',
        Primaryemail: udata?udata.email?udata.email:'':'',
        mobileNumber: "",
        birthDate:"",
        password: "",
        confirmpassword: "",
        privacy: false,
        mailMarketing:false,
        smsMarketing:false,
        cpf:"",
        socialKey:udata?udata.authId:'',
        isSocialMedia:udata?udata.isSocialMedia:false,
        socialMediaType:udata?udata.socialType:'',
        qrcode: localStorage.getItem("qrcode")
          ? decryptData(localStorage.getItem("qrcode"))
          : ""
      },
      submitted: false,
      showPopup: false,
      popupmsg: "",
      imageType:"",
      isOpenCountryList:false,
      selectedCountry:{
        name: "Singapore",
        dial_code: "+65",
        code: "SG"
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.closingPopup = this.closingPopup.bind(this);
    this.handletDob = this.handletDob.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    if(name=='mobileNumber'){
      let mob = value.split('-').join('');
      mob = mob.split('(').join('');
      mob = mob.split(' ').join('');
      mob = mob.split(')').join('');
      mob = mob.split('_').join('');
      this.setState({
        user: {
          ...user,
          [name]: mob,
          smsMarketing:mob?true:false
        },
      });
    }
    else if(name=="Primaryemail"){
      if(validator.isEmail(value)){
        this.setState({
          isInvalidEmail:false
        })
      }
      else{
        this.setState({
          isInvalidEmail:true
        })
      }
      this.setState({
        user: {
          ...user,
          [name]: value,
        },
      });
    }
    else if(name=='cpf'){
      let cpf = value.split('-').join('');
      cpf = cpf.split('.').join('');
      cpf = cpf.split('_').join('');
      this.setState({
        user: {
          ...user,
          [name]: cpf,
        },
      });
    }
    else{
      this.setState({
        user: {
          ...user,
          [name]: value,
        },
      });
    }
  }
  checkNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
    return true;
  }

  handletDob(event){
    var value = event.target.value
    let dob = value.split('/').join('');
    dob = dob.split('_').join('');
    const { user } = this.state;
    if(dob.length==8){
      let date = value.split("/").reverse().join("-"); 
      date = moment(date).format('yyyy-MM-DD')
      if(date!="Invalid date"){
        let age = this.checkAge(date);
        if(age<config.minAge || age>=config.maxAge){
          this.setState({
            popupmsg: i18next.t('ValidateMsg_ForMinMaxDoB',{minAge:config.minAge}),
            showPopup:true
          })
        }
        this.setState({
          user:{
            ...user,
            birthDate:value
          }
        }) 
      }
      else{
        this.setState({
          user:{
            ...user,
            birthDate:value
          },
          popupmsg: i18next.t('ValidateMsg_ForInvalidDoB'),
          showPopup:true
        })
      }      
    }
    else{
      this.setState({
        user:{
          ...user,
          birthDate:value
        }
      }) 
    }
       
  }

  checkAge(date){
    var today = new Date();
      var birthDate = new Date(date);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;
  }

  privacyChecked(event) {
    var privacyBool = event.target.checked;
    var { user } = this.state;
    if (privacyBool) {
      user.privacy = privacyBool;
      this.setState({
        user: user,
      });
    } else {
      user.privacy = privacyBool;
      this.setState({
        user: user,
      });
    }
  }

  marketingChecked(event){
    const { name, checked } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: checked,
      },
    });
  }

  closingPopup() {    
    if (this.state.popupmsg === <Trans i18nKey="SignupSuccess" components={[<br />]} /> ) {
      history.push("/logIn", {
        state: { qrcode: this.state.user.qrcode },
      });
    }
    else{
      
      this.setState({
        showPopup: false,
        popupmsg: ""
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.clearAlerts();
    const { user } = this.state;
    // this.setState({ submitted: true });
    if (
      user.firstName.trim() &&
      user.lastName.trim() &&
      user.Primaryemail.trim() &&
      user.birthDate
    ) {

      var validatelowerCaseLettersBool = user.password.match(/[a-z]/g)
        ? true
        : false;
      var validateupperCaseLettersBool = user.password.match(/[A-Z]/g)
        ? true
        : false;
      var validatenumbersBool = user.password.match(/[0-9]/g) ? true : false;
      var validateLengthBool = user.password.length >= 8 ? true : false;
      var validatePassword =
        validatelowerCaseLettersBool &&
        validateupperCaseLettersBool &&
        validatenumbersBool &&
        validateLengthBool;
      var age = 0;
      let dateofBirth = user.birthDate.split("/").reverse().join("-"); 
      dateofBirth = moment(dateofBirth).format('yyyy-MM-DD')
      age = this.checkAge(dateofBirth);
      const mobile = user.mobileNumber===""?"":parseInt(user.mobileNumber).toString()
      if (config.applicationTypeId==9 && !user.cpf.trim()) {
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForCPF"),
        });
      } 
      else if(user.cpf.length<11 && config.applicationTypeId==9){
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForInvalidCPF"),
        });
      }
      else if((mobile.length>0 && mobile.length>config.mobileLength) || (mobile.length>0 && mobile.length<config.mobileLength && !config.isFreeTextForMobile)){
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForMobile",{'length':config.mobileLength}),
        });
      }
      else if(dateofBirth=='Invalid date' ||  (age<config.minAge  || age>=config.maxAge)){
        this.setState({
          popupmsg: i18next.t('ValidateMsg_ForInvalidDoB'),
          showPopup:true
        })
      }
      else if(validator.isEmail(user.Primaryemail)){
        this.setState({})
        this.setState({
        isLoading:true,
        showPopup: false,
        popupmsg: "",
        });
        // user.birthDate = dateofBirth;
        this.SocialReg(user)
      }
      else{
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForInvalidEmail"),
        });
      }
    } else {
      if (!user.firstName.trim()) {
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForFirstName"),
        });
      } else if (!user.lastName.trim()) {
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForLastName"),
        });
      } else if (!user.Primaryemail.trim()) {
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForEmail"),
        });
      }
      else if(!user.birthDate){
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForDoB"),
        });
      }
      
    }
  }

  SocialReg(user){
    this.setState({
      isLoading:true        
    })
    const primaryEmail = user.Primaryemail;
    const birthDate = user.birthDate.split("/").reverse().join("-");
    const firstName = user.firstName;
    const lastName = user.lastName;
    const authId = user.socialKey;
    const socialMediaType = user.socialMediaType
    const applicationTypeId = config.applicationTypeId;
    const userTypeId = (config.applicationTypeId==9)?2:1;
    const cpf = user.cpf;
    let mob=this.state.selectedCountry.dial_code+"-"+user.mobileNumber
      if(config.countryDropDown){
        user.mobileNumber=mob;
      }
    const mobileNumber = user.mobileNumber;
    const mailMarketing = user.mailMarketing;
    const smsMarketing = user.smsMarketing;
    const isSocialMedia = true;
    const isManualEmail  = !this.props.location.state.email?false:true;
    let sessionUId = sessionStorage.getItem("sessionId");
    if(!sessionUId){
        sessionUId = uuidv4();
        sessionStorage.setItem('sessionId',sessionUId);
    }
    // adding platform for report
    const platform = "webapp";
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            primaryEmail,
            birthDate,
            firstName,
            lastName,
            authId,
            socialMediaType,
            applicationTypeId,
            userTypeId,
            cpf,
            mobileNumber,
            mailMarketing,
            smsMarketing,
            isSocialMedia,
            isManualEmail,
            sessionUId,
            platform
        })
    };

    fetch(`${config.envUrl}VendingAppApi/userRegistrationSocialMedia`, requestOptions)
    .then(function (response) {
        return response.json();
    })
    .catch(err => {
        console.log(err)
    })
    .then(data => {
        if(data.success && data.code===5002){
            this.SocialSignIn(user)
        }
        else{
          let msg = ''
          if(data.code===1527){
            msg = i18next.t("UserExist")
          }
          else if(data.code===15487){
              msg = i18next.t("Oops! It looks like you used this email to log in with social media. Please log in with your social media account to continue")
          }
          else if(data.code===15503){
            msg=i18next.t("Por favor, insira um CPF válido")
          }
          else if(data.code===15505){
            msg=i18next.t("A data de nascimento informada não coincide com a do portador do CPF informado")
          }
          else if(data.code===15504){
            msg=i18next.t("Por favor, insira um CPF válido")
          }
          else{
            msg = i18next.t("CommunicationError")
          }
          this.setState({
            isLoading:false,
            showPopup:true,
            popupmsg:msg,
            imageType:'error'
          })
        }
    });
}

    SocialSignIn(user){
        const applicationTypeId = config.applicationTypeId;
        const email = user.Primaryemail;
        const socialKey = user.socialKey;
        const isSocialMedia = true;
        const socialMediaType = user.socialMediaType
        let sessionUId = sessionStorage.getItem("sessionId");
        if (!sessionUId) {
            sessionUId = uuidv4();
            sessionStorage.setItem('sessionId', sessionUId);
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                email,
                socialKey,
                isSocialMedia,
                socialMediaType,
                applicationTypeId,
                sessionUId
            })
        };

        fetch(`${config.envUrl}VendingAppApi/loginV2`, requestOptions)
        .then(function (response) {
            return response.json();
        })
        .catch(err => {
            console.log(err)
        })
        .then(data => {
            if(!data.success &&  data.code===1523){
                // history.push({pathname:'/socialsignup',state:user})
            }
            else{
                let hrs = new Date();
                hrs.setHours(hrs.getHours() + 24);
                data.expiry = hrs;
                let authToken = data.authToken
                let macAddress= decryptData(localStorage.getItem('qrcode'))
                let PurchaseRequestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams({authToken,applicationTypeId,macAddress})
                }
                let that = this;
                fetch(`${config.envUrl}VendingAppApi/getPurchaseOfferQty`, PurchaseRequestOptions)
                .then(function (response) {
                    return response.json();
                })
                .then((result) => {
                    localStorage.setItem('isRewardAvailable',encryptData(JSON.stringify(result.records)));
                }).catch(function (error) {
                    console.log(error)
                    that.setState({
                        isLoading:false
                    })
                });
                const requestOptions = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: new URLSearchParams({
                      authToken,
                      applicationTypeId,
                      macAddress
                    })
                  };
                  fetch(
                    `${config.envUrl}VendingAppApi/getConsumerOfferDetails`,
                    requestOptions
                  )
                  .then(function (response) {
                    return response.json();
                })
                .then(function(body){  
                    localStorage.setItem('user', encryptData(JSON.stringify(data)));
                    localStorage.setItem('rememberme',"false");
                    // localStorage.setItem('rewardPoint', 0);
                    localStorage.setItem('reward',encryptData(JSON.stringify(body)));
                    localStorage.setItem('isEncrypted','true')
                    history.push("/Scanbutton")
                })
            }
        });
    } 

    componentWillReceiveProps(props) {
        if (props.alert.message) {
            if(props.alert.message=="success"){
                console.log('success')
            }
            else{
                this.setState({
                    isLoading:false,
                    showPopup: true,
                    popupmsg:props.alert.message
                });
            }
        }
    }
    openCountryList=()=>{
      this.setState({
        isOpenCountryList:true
      })
    }
    SelectCountry=(country)=>{
      this.setState({
        isOpenCountryList:false,
        selectedCountry:country
      })
    }
  
    closeList=()=>{
      this.setState({
        isOpenCountryList:false
      })
    }
  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return (
      <div className="loginwith">
        <div className="container">
            <Heading Title={i18next.t('SignUp')} backLink="/socialLogin" subHeading={i18next.t('SignUpHeading')} Desc={i18next.t('SignUpDesc')} Required={i18next.t('Required')} />
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
                    <div className="col-12 p-0">
                      <form onSubmit={this.handleSubmit}>
                        <RegisterForm
                          user={this.state.user}
                          handleChange={(event) => this.handleChange(event)}
                          privacyChecked={(event) => this.privacyChecked(event)}
                          handleDoBchange={this.handletDob}
                          dobvalue={this.state.birthDate}
                          privacy={this.state.user.privacy}
                          mailMarketing={this.state.user.mailMarketing}
                          smsMarketing={this.state.user.smsMarketing}
                          checkNumber={(event)=>this.checkNumber(event)}
                          marketingChecked={(event)=> this.marketingChecked(event)}
                          isShowPassword={false}
                          isEmailReadOnly={this.props.location.state.email}
                          isInvalidEmail={this.state.isInvalidEmail}
                          openCountryList={this.openCountryList}
                          selectedCountry={this.state.selectedCountry}
                        ></RegisterForm>
                        {
                          this.state.isOpenCountryList &&
                          <CountryList selectCountry={this.SelectCountry} closeList={this.closeList}/>
                        }
                        <div className="mt-5">
                          <Button size="large" btnType="submit" disabled={(!this.state.user.privacy || this.state.isInvalidEmail)?true:false} text={i18next.t('Finish')} />
                        </div>
                      </form>
                    </div>
                </div>
            </div>
            { this.state.showPopup ? (
                <CoolerPopup
                  color="Red"
                  openPopup={
                    this.state.showPopup
                      ? true
                      : false
                  }
                  closePopup={this.closingPopup}
                  message={ this.state.popupmsg }
                  imagetype={ this.state.imageType }
                />
              ) : (
                ""
              )}
              <div className="spinner" style={{display:this.state.isLoading?'block':'none'}}>
                <div style={style}>
                    <ClipLoader
                    size={50}
                    color={"#fff"}
                    loading={this.state.isLoading ? true : false}
                    />
                </div>
            </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { registering } = state.registration;
  const alert = state.alert;
  return { registering, alert };
}

const actionCreators = {
  register: userActions.register,
  clearAlerts: alertActions.clear,
};

const connectedRegisterPage = connect(mapState, actionCreators)(SocialSignUp);
export { connectedRegisterPage as SocialSignUp };
