import {
    config, PaymentGatewayList
} from '../_config/index';
import { decryptData, encryptData } from '../_helpers/encryptData';
import { v4 as uuidv4 } from "uuid";

function getCardToken(request){
    let deviceId = decryptData(localStorage.getItem("qrcode"));
    const applicationTypeId = config.applicationTypeId
    let sessionUId = sessionStorage.getItem('sessionId');
    let RequestData = {
        amount: 10,
        deviceId: deviceId,
        authToken: request.authToken,
        desc: 'text',
        applicationTypeId:applicationTypeId,
        sessionUId:sessionUId
    } 
    let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(RequestData)
    }

    return fetch(`${config.envUrl}VendingAppApi/addPayment`, RequestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            return result;
        }).catch(function (error) {
            return {
                success: false,
                error: "NoInternet"
            }
        });
}

function getCardList(qrcode=""){
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    let deviceId = qrcode?qrcode:decryptData(localStorage.getItem("qrcode"));
    // let deviceId = '1C:CA:E3:20:23:24';
    const applicationTypeId = config.applicationTypeId;
    let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({authToken,deviceId,applicationTypeId})
    }

    return fetch(`${config.envUrl}VendingAppApi/fetchStoredPayment`, RequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((result) => {
        return result;
    }).catch(function (error) {
        return {
            success: false,
            error: error
        }
    });
}
function removeCard(card){
    // console.log(card)
    const {MacAddress,cardID} = card
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    let deviceId = MacAddress;
    const applicationTypeId = config.applicationTypeId;
    let sessionUId = sessionStorage.getItem('sessionId');

    let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({authToken,deviceId,cardID,applicationTypeId,sessionUId})
    }

    return fetch(`${config.envUrl}VendingAppApi/removeCardDetail`, RequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((result) => {
        console.log(result)
        return result;
    }).catch(function (error) {
        return {
            success: false,
            error: error
        }
    });
}

function preauthorize(request){
    let paymentFlow = sessionStorage.getItem('paymentFlow')
    let paymentGateway = sessionStorage.getItem('paymentGateway')
    if(config.applicationTypeId==19 && paymentFlow=="NEW" && paymentGateway=="payu"){
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        let deviceId = decryptData(localStorage.getItem("qrcode"));
        let cardId = request.cardID;
        let data={
            authToken:user.authToken,
            deviceId:deviceId,
            applicationTypeId:19,
            cardId:cardId,
            sessionUId:sessionStorage.getItem('sessionUId')

        }
        let RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams(data)
        }
        return fetch(`${config.envUrl}VendingAppApi/checPayuMandate`, RequestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            return {
                transactionID:uuidv4(),
                success:true
            }
        }).catch(function (error) {
            return {
                success: false,
                error: error
            }
        });
    }
    else if(request.cardID=="" && config.isSaferPayWalletEnabled){
        var saferPurchaseNumber = sessionStorage.getItem('purchaseNumber')
        return Promise.resolve({
            transactionID:saferPurchaseNumber,
            success:true
        })
    }
    else{    
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        let authToken = user.authToken;
        let CustomerName = user.firstName+" "+user.lastName;
        let cardId = request.cardID;
        let desc = request.paymentmethod;
        let deviceId = decryptData(localStorage.getItem("qrcode"));
        const applicationTypeId = config.applicationTypeId;
        let Amount = request.amt;
        let cvv = request.cvv;
        let currencyCode = config.currencyCode
        let paymentmethod = request.paymentmethod
        let uniquedeviceId = config.applicationTypeId==19? user.userId:"";
        let sessionUId = sessionStorage.getItem('sessionId');
        let data;
        if(config.paymentGateway==4){
            if(paymentFlow=='CARD')
            {
                data = {authToken,desc,Amount,applicationTypeId,currencyCode,deviceId,paymentmethod,cardId,uniquedeviceId,sessionUId,cvv}
            }
            else{
                data = {authToken,desc,Amount,applicationTypeId,currencyCode,deviceId,paymentmethod,cardId,uniquedeviceId,sessionUId}
            }
        }
        else{
            data = {authToken,desc,Amount,applicationTypeId,currencyCode,deviceId,paymentmethod,cardId,sessionUId}
        }
        let RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams(data)
        }

        return fetch(`${config.envUrl}VendingAppApi/authorizeV1`, RequestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            return result;
        }).catch(function (error) {
            return {
                success: false,
                error: error
            }
        });
    }
}

function setDefaultCard(request){
    let authToken = request.authToken;
    let cardID = request.cardID;
    const applicationTypeId = config.applicationTypeId;
    let sessionUId = sessionStorage.getItem("sessionId");
    let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({authToken,cardID,applicationTypeId,sessionUId})
    }

    return fetch(`${config.envUrl}VendingAppApi/updateDefaultCardId`, RequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((result) => {
        return result;
    }).catch(function (error) {
        return {
            success: false,
            error: error
        }
    });
}
function addTransactionId(request){
    let authToken = request.authToken;
    let transactionId = request.transactionId;
    const applicationTypeId = config.applicationTypeId;
    let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({authToken,transactionId,applicationTypeId})
    }

    return fetch(`${config.envUrl}VendingAppApi/addTransactionId`, RequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((result) => {
        return result;
    }).catch(function (error) {
        return {
            success: false,
            error: error
        }
    });
}
function cancelTransaction(transactionId,preAuthorizeAmount=0){
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    let deviceId = decryptData(localStorage.getItem("qrcode"));
    const applicationTypeId = config.applicationTypeId;
    const uniqeDeviceId = sessionStorage.getItem("uniquedeviceId") || "";
    let sessionUId = sessionStorage.getItem("sessionId");
    var requestData;
    if(config.paymentGateway==4){
        let amount = preAuthorizeAmount
        requestData = {authToken,transactionId,deviceId,applicationTypeId,uniqeDeviceId,sessionUId,amount}
    }
    else{
        requestData = {authToken,transactionId,deviceId,applicationTypeId,sessionUId}
    }
    let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(requestData)
    }

    return fetch(`${config.envUrl}VendingAppApi/cancelTransaction`, RequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((result) => {
        return result;
    }).catch(function (error) {
        return {
            success: false,
            error: error
        }
    });
}

function couponList(){
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    const applicationTypeId = config.applicationTypeId;
    let macAddress= decryptData(localStorage.getItem('qrcode'))
    return fetch(config.envUrl + "VendingAppApi/getConsumerOfferDetails", {
        method: "POST",
        body: new URLSearchParams({
            authToken,
            applicationTypeId,
            macAddress
        }),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    })
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        localStorage.setItem('reward',encryptData(JSON.stringify(data)))
        return data;
    //   this.setState({
    //     offerCoupon: data.OfferCoupon,
    //     isCouponAvailable:data.OfferCoupon.length>0?true:false
    //   })
    })
    .catch(function (error) {
        return {
            success: false,
            error: error
        }
    });    
}

function getPurchaseQty(){
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    const applicationTypeId = config.applicationTypeId;
    let macAddress= decryptData(localStorage.getItem('qrcode'))
    let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({authToken,applicationTypeId,macAddress})
    }
    return fetch(`${config.envUrl}VendingAppApi/getPurchaseOfferQty`, RequestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            localStorage.setItem('isRewardAvailable',encryptData(JSON.stringify(result.records)))
            return result;
        })
        .catch(function (error) {
            return {
                success: false,
                error: error
            }
        });
}

function MqttLog(request){
    let deviceMACAddress = request.MacAddress;
    let userId = request.userId;
    let doorStatus = request.doorStatus;
    let error = request.error;
    let sts = request.Sts;
    const applicationTypeId = config.applicationTypeId;
    return fetch(config.envUrl + "VendingAppApi/updateMqttLogV2", {
        method: "POST",
        body: new URLSearchParams({
            deviceMACAddress,
            userId,
            doorStatus,
            error,
            sts,
            applicationTypeId
        }),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    })
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        return data;
    })
    .catch(function (error) {
        return {
            success: false,
            error: error
        }
    });
}

function MqttMachineLog(request){
    let smartDeviceId = request.smartDeviceId;
    let userId = request.userId;
    let purchaseData = request.purchaseData;
    const applicationTypeId = config.applicationTypeId;
    return fetch(config.envUrl + "VendingAppApi/vendingMachineAppLog", {
        method: "POST",
        body: new URLSearchParams({
            smartDeviceId,
            userId,
            purchaseData,
            applicationTypeId
        }),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    })
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        return data;
    })
    .catch(function (error) {
        return {
            success: false,
            error: error
        }
    });
}
function WebAppLog(request){
    let authToken = request.authToken;
    let smartDeviceId = request.smartDeviceId;
    let deviceResponse = request.deviceResponse;
    const applicationTypeId = config.applicationTypeId;
    fetch(config.envUrl + "VendingAppApi/addWebAppLogDetails", {
        method: "POST",
        body: new URLSearchParams({
            smartDeviceId,
            authToken,
            deviceResponse,
            applicationTypeId
        }),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    })
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        // console.log(data)
        // return data;
    })
    .catch(function (error) {
        console.log({error})       
    });
}
export const cardService = {
    getCardToken,
    getCardList,
    removeCard,
    preauthorize,
    setDefaultCard,
    addTransactionId,
    cancelTransaction,
    couponList,
    MqttLog,
    MqttMachineLog,
    WebAppLog,
    getPurchaseQty
};