import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import "../_assets/scss/survey.scss";
import left_angle from "../_assets/images/icons/back.png"
import { ClipLoader } from "react-spinners";
import { config } from '../_config';
import { history } from '../_helpers';
import i18next from 'i18next';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { validateString } from '../_helpers/encryptData';
class Survey extends Component {
    constructor(props){
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        this.applicationTypeId = query.get('applicationtypeid')
        this.isHeaderVisible = query.get('isHeaderVisible')
        this.authToken = query.get('authtoken')
        this.isB10G1Enabled = query.get('isB10G1Enabled')
        this.languageCode = query.get('languageCode')
        // this.onFormSubmit = this.onFormSubmit.bind();   
        document.title=`Coke & Go ${config.countryName} | consumer-survey`;
        CapturePage({hitType:"pageview",page:"/consumer-survey"})
        this.state = {
            isLoading:false,
            isBtnDisable:true,
            surveydata:{
                AnswerQ1Opt1:'',
                AnswerQ1Opt2:'',
                AnswerQ1Opt3:'',
                AnswerQ1Opt4:'',
                AnswerQ1Opt5:'',
                AnswerQ2Opt1:'',
                AnswerQ3Opt1:'',
                AnswerQ3Opt2:'',
                AnswerQ4Opt1:'',
                AnswerQ4Opt2:'',
                AnswerQ5Opt1:'',
                AnswerQ5Opt2:'',
                AnswerQ6Opt1:'',
                AnswerQ6Opt2:'',
                AnswerQ6Opt3:'',
                AnswerQ6Opt4:'',
                AnswerQ6Opt5:'',
                AnswerQ6Opt6:'',
                AnswerQ7Opt1:'',
                AnswerQ7Opt2:'',
                AnswerQ8Opt1:''
            }
        }     
        this.handleSelection = this.handleSelection.bind(this);
        if(this.isHeaderVisible=="false"){
            localStorage.setItem("selectedLanguage",this.capitalizeString(this.languageCode))
            i18next.changeLanguage(this.capitalizeString(this.languageCode))
        }
    }

    capitalizeString(text){
        return text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    }
    onFormSubmit=(event)=>{
        event.preventDefault();
        let that = this;
        that.setState({
            isLoading:true
        })
        const formData = new FormData(event.target);
        const applicationTypeId=this.applicationTypeId;
        const authToken = this.authToken;
        const userId = '1110';// need to remove as discuss with Kanhya
        let sessionUId = sessionStorage.getItem("sessionId");
        let data = {applicationTypeId,authToken,userId,...this.state.surveydata,sessionUId
        }

        fetch(config.envUrl + "VendingAppApi/submitSurvey3Answer", {
        method: "POST",
        body: new URLSearchParams(data),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        })
        .then(function (response) {
        return response.json();
        })
        .catch(function (error) {
            history.push(`/consumer-survey-error?applicationtypeid=${applicationTypeId}&authtoken=${authToken}&code=500&success=false`);
        })
        .then(function (body) {
            if(body.success && body.code===5049){
                history.push(`/consumer-survey-success?applicationtypeid=${applicationTypeId}&authtoken=${authToken}&code=5049&success=true`);
            }
            else{
                history.push(`/consumer-survey-error?applicationtypeid=${applicationTypeId}&authtoken=${authToken}&code=${body.code}&success=false`);
            }
        })
    }

    handleSelection(e){
        var testString=true;
        const {name,value} = e.target
        let optionData = this.state.surveydata;
        if(name=='AnswerQ6Opt1' || name=='AnswerQ6Opt2' || name=='AnswerQ6Opt3' || name=='AnswerQ6Opt4' || name=='AnswerQ6Opt5'){
            if(optionData[name]==""){
                optionData = {...optionData,[name]:value.trim()};
            }
            else{
                optionData = {...optionData,[name]:""};
            }
        }
        else{
            if(name=='AnswerQ3Opt2' || name=='AnswerQ5Opt2' || name=='AnswerQ6Opt6' || name=='AnswerQ8Opt1' || name=='AnswerQ7Opt2'){
                testString = validateString(value,'description');                
            }
            if(testString)
            optionData = {...optionData,[name]:value.trim()};
        }
        let isBtnDisable=true;
        let {AnswerQ1Opt1, AnswerQ1Opt2, AnswerQ1Opt3, AnswerQ1Opt4, AnswerQ1Opt5, AnswerQ2Opt1, AnswerQ3Opt1, AnswerQ3Opt2, AnswerQ4Opt1, AnswerQ4Opt2, AnswerQ5Opt1, AnswerQ5Opt2, AnswerQ6Opt1, AnswerQ6Opt2, AnswerQ6Opt3, AnswerQ6Opt4, AnswerQ6Opt5, AnswerQ6Opt6, AnswerQ7Opt1, AnswerQ7Opt2, AnswerQ8Opt1} = optionData
        
        if(((this.isB10G1Enabled=='1' && AnswerQ1Opt1 && AnswerQ1Opt2 && AnswerQ1Opt3 && AnswerQ1Opt4 && AnswerQ1Opt5 && AnswerQ2Opt1 && AnswerQ3Opt1 && AnswerQ4Opt1 && AnswerQ4Opt2 && AnswerQ5Opt1 && AnswerQ7Opt1 && AnswerQ8Opt1) && (AnswerQ6Opt1 || AnswerQ6Opt2 ||AnswerQ6Opt3 || AnswerQ6Opt4 || AnswerQ6Opt5)) || ((this.isB10G1Enabled!='1' && AnswerQ2Opt1 && AnswerQ3Opt1 && AnswerQ4Opt1 && AnswerQ4Opt2 && AnswerQ5Opt1 && AnswerQ7Opt1 && AnswerQ8Opt1) && (AnswerQ6Opt1 || AnswerQ6Opt2 ||AnswerQ6Opt3 || AnswerQ6Opt4 || AnswerQ6Opt5))){
            if(AnswerQ3Opt1=="No" && AnswerQ3Opt2==""){
                isBtnDisable=true
            }
            else if(AnswerQ5Opt1=="Other" && AnswerQ5Opt2==""){
                isBtnDisable=true
            }
            else if(AnswerQ6Opt5 && AnswerQ6Opt6==""){
                isBtnDisable=true
            }
            else if(AnswerQ7Opt1=="Other" && AnswerQ7Opt2==""){
                isBtnDisable=true
            }
            else{
                isBtnDisable=false
            }
        }
        
        this.setState({
            isBtnDisable:isBtnDisable,
            surveydata:optionData
        })
    }
    clearTextArea=(value,fieldName)=>{
        if(value=="Yes"){
            let {surveydata} = this.state
            surveydata[fieldName] = ""
            this.setState({
                surveydata: surveydata
            })
        }
    }
    render() {
        const surveydata = this.state.surveydata;
        let i = 1;
        // if(this.isB10G1Enabled=='1'){
        //     i++
        // }
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        return (
            <>
                {
                    this.applicationTypeId==null || this.authToken == null ?
                    <div className='surver-error'>
                        {
                            (this.applicationTypeId==null && this.authToken == null) ?
                            <h4>Application Type Id and Auth Token are missing!</h4>
                            :
                            this.applicationTypeId==null ?
                            <h4>Application Type Id is missing!</h4>
                            :
                            <h4>Auth Token is missing!</h4>
                        }
                    </div>
                    :                    
                    <>                        
                        <div className='survey-form'>
                            {
                                this.isHeaderVisible != "false" &&
                                <div className='py-3'>
                                    <div className='row'>
                                        <div className='col-12 text-center'><h6 className='mb-0 text-white px-3'>{i18next.t("Coke&Go - We Value Your Feedback")}</h6>
                                        <Link to="/ScanButton" className='survey-back'><img src={left_angle} height="15"/></Link>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="text_left line40">{i18next.t("Your feedback helps make Coke&Go the best it can be. We appreciate it!")}</div>
                            <br/>
                            <form onSubmit={this.onFormSubmit} >
                                
                                <p><b>{i++}. {i18next.t("How easy was it for you to...")}</b></p>
                                <div className='table-responsive'>
                                    <table border="0" className="table">
                                        <tbody>
                                            <tr>
                                                <th valign="top" width="25%"></th>
                                                <th valign="top" className='nowrap'>{i18next.t("Very easy")}</th>
                                                <th valign="top" className='nowrap'>{i18next.t("Somewhat easy")}</th>
                                                <th valign="top" className='nowrap'>{i18next.t("Neutral")}</th>
                                                <th valign="top" className='nowrap'>{i18next.t("Somewhat difficult")}</th>
                                                <th valign="top" className='nowrap'>{i18next.t("Very difficult")}</th>
                                            </tr>
                                            <tr>
                                                <td style={{whiteSpace:'pre'}}><strong>{i18next.t("Create  a Coke&Go account")}</strong></td>
                                                <td align="center"><input id="AnswerQ1Opt1" type="radio" name="AnswerQ1Opt1" value="Very easy" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt1" type="radio" name="AnswerQ1Opt1" value="Somewhat easy" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt1" type="radio" name="AnswerQ1Opt1" value="Neutral" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt1" type="radio" name="AnswerQ1Opt1" value="Somewhat difficult" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt1" type="radio" name="AnswerQ1Opt1" value="Very difficult" onChange={this.handleSelection}/></td>
                                            </tr>
                                            <tr>
                                                <td><strong>{i18next.t("Use the Coke&Go app")}</strong></td>
                                                <td align="center"><input id="AnswerQ1Opt2" type="radio" name="AnswerQ1Opt2" value="Very easy" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt2" type="radio" name="AnswerQ1Opt2" value="Somewhat easy" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt2" type="radio" name="AnswerQ1Opt2" value="Neutral" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt2" type="radio" name="AnswerQ1Opt2" value="Somewhat difficult" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt2" type="radio" name="AnswerQ1Opt2" value="Very difficult" onChange={this.handleSelection}/></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                            {
                                                                (this.applicationTypeId == 12 || this.applicationTypeId == 24 || this.applicationTypeId == 32)
                                                                    ?
                                                                    i18next.t("Scan the QR code")
                                                                    :
                                                                    i18next.t("Scan and unlock the cooler")
                                                            }
                                                    </strong>
                                                </td>
                                                <td align="center"><input id="AnswerQ1Opt3" type="radio" name="AnswerQ1Opt3" value="Very easy" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt3" type="radio" name="AnswerQ1Opt3" value="Somewhat easy" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt3" type="radio" name="AnswerQ1Opt3" value="Neutral" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt3" type="radio" name="AnswerQ1Opt3" value="Somewhat difficult" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt3" type="radio" name="AnswerQ1Opt3" value="Very difficult" onChange={this.handleSelection}/></td>
                                            </tr>
                                            <tr>
                                                <td><strong>{i18next.t("Make a purchase")}</strong></td>
                                                <td align="center"><input id="AnswerQ1Opt4" type="radio" name="AnswerQ1Opt4" value="Very easy" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt4" type="radio" name="AnswerQ1Opt4" value="Somewhat easy" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt4" type="radio" name="AnswerQ1Opt4" value="Neutral" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt4" type="radio" name="AnswerQ1Opt4" value="Somewhat difficult" onChange={this.handleSelection}/></td>
                                                <td align="center"><input id="AnswerQ1Opt4" type="radio" name="AnswerQ1Opt4" value="Very difficult" onChange={this.handleSelection}/></td>
                                            </tr>
                                            {
                                                this.isB10G1Enabled=='1' &&
                                                
                                                <tr>
                                                    <td><strong>{i18next.t("Redeem your FREE beverage")}</strong></td>
                                                    <td align="center"><input id="AnswerQ1Opt5" type="radio" name="AnswerQ1Opt5" value="Very easy" onChange={this.handleSelection}/></td>
                                                    <td align="center"><input id="AnswerQ1Opt5" type="radio" name="AnswerQ1Opt5" value="Somewhat easy" onChange={this.handleSelection}/></td>
                                                    <td align="center"><input id="AnswerQ1Opt5" type="radio" name="AnswerQ1Opt5" value="Neutral" onChange={this.handleSelection}/></td>
                                                    <td align="center"><input id="AnswerQ1Opt5" type="radio" name="AnswerQ1Opt5" value="Somewhat difficult" onChange={this.handleSelection}/></td>
                                                    <td align="center"><input id="AnswerQ1Opt5" type="radio" name="AnswerQ1Opt5" value="Very difficult" onChange={this.handleSelection}/></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                    
                                <br/>
                                    
                                <table width="100%" border="0" className="table_radio">
                                    <tbody>
                                        <tr>
                                            <th colSpan="6" align="left">{i++}.	{i18next.t("Were you charged for the correct product?")}</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input id="AnswerQ2Opt1" type="radio" name="AnswerQ2Opt1" value="Yes" onChange={this.handleSelection}/>
                                                <label  className='ml-2'>{i18next.t("Yes")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="100%">
                                                <input id="AnswerQ2Opt1" type="radio" name="AnswerQ2Opt1" value="No" onChange={this.handleSelection}/>
                                                <label  className='ml-2'>{i18next.t("No")}</label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                    
                                <br/>

                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="table_radio">
                                    <tbody>
                                        <tr>
                                            <th colSpan="6" align="left">{i++}. {
                                                        (this.applicationTypeId == 12 || this.applicationTypeId == 24  || this.applicationTypeId == 32)
                                                            ?
                                                            i18next.t("Did you find the product you wanted?")
                                                            :
                                                            i18next.t("Did you find the product you wanted in the cooler?")
                                                    }     
                                            </th>
                                        </tr>                                
                                        <tr>
                                            <td>
                                                <input id="AnswerQ3Opt1" type="radio" name="AnswerQ3Opt1" value="Yes" onChange={this.handleSelection} onClick={()=> this.clearTextArea("Yes","AnswerQ3Opt2")}/>
                                                <label  className='ml-2'>{i18next.t("Yes")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="100%">
                                                <input id="AnswerQ3Opt1" type="radio" name="AnswerQ3Opt1" value="No" onChange={this.handleSelection}/>
                                                <label  className='ml-2'>{i18next.t("No")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="100%"> 
                                                <label>{i18next.t("What was missing?")}</label>
                                                <textarea className="form-control" name="AnswerQ3Opt2" disabled={this.state.surveydata.AnswerQ3Opt1=="No" ? false : true} onChange={this.handleSelection} value={surveydata.AnswerQ3Opt2}></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                    
                                <br/>

                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="table_radio padding_bottom10">
                                    <tbody>
                                        <tr>
                                            <th colSpan="13"  align="left">{i++}. {i18next.t("How likely are you to…")}</th>
                                        </tr>
                                        <tr>
                                            <th valign="top" colSpan="13" className="pl_20 pt-3">{i18next.t("Recommend Coke&Go to a friend or family member?")}</th>
                                        </tr>
                                    </tbody>
                                </table>
                                    
                                <div className='table-responsive'>
                                    <table className="table">
                                        <tbody>
                                            <tr>                                    
                                                <th width="12%" className="ratinghead">{i18next.t("Not at all likely")}</th>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="0" onChange={this.handleSelection}/><br/>0
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="1" onChange={this.handleSelection}/><br/>1
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="2" onChange={this.handleSelection}/><br/>2
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="3" onChange={this.handleSelection}/><br/>3
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="4" onChange={this.handleSelection}/><br/>4
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="5" onChange={this.handleSelection}/><br/>5
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="6" onChange={this.handleSelection}/><br/>6
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="7" onChange={this.handleSelection}/><br/>7
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="8" onChange={this.handleSelection}/><br/>8
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="9" onChange={this.handleSelection}/><br/>9
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="10" onChange={this.handleSelection}/><br/>10
                                                </td>
                                                <td align="center" className="ratinghead"><b>{i18next.t("Extremely likely")}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br/>
                                <div className="padding_bottom10"><b>{i18next.t("Buy from Coke&Go again")}</b> </div>
                                <div className='table-responsive'>
                                    <table className="table">
                                        <tbody>
                                            <tr>                                    
                                                <th width="12%" className="ratinghead">{i18next.t("Not at all likely")}</th>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt2" type="radio" name="AnswerQ4Opt2" value="0" onChange={this.handleSelection}/><br/>0
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt2" type="radio" name="AnswerQ4Opt2" value="1" onChange={this.handleSelection}/><br/>1
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt2" type="radio" name="AnswerQ4Opt2" value="2" onChange={this.handleSelection}/><br/>2
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt2" type="radio" name="AnswerQ4Opt2" value="3" onChange={this.handleSelection}/><br/>3
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt2" type="radio" name="AnswerQ4Opt2" value="4" onChange={this.handleSelection}/><br/>4
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt2" type="radio" name="AnswerQ4Opt2" value="5" onChange={this.handleSelection}/><br/>5
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt2" type="radio" name="AnswerQ4Opt2" value="6" onChange={this.handleSelection}/><br/>6
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt2" type="radio" name="AnswerQ4Opt2" value="7" onChange={this.handleSelection}/><br/>7
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt2" type="radio" name="AnswerQ4Opt2" value="8" onChange={this.handleSelection}/><br/>8
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt2" type="radio" name="AnswerQ4Opt2" value="9" onChange={this.handleSelection}/><br/>9
                                                </td>
                                                <td width="6%" align="center">
                                                    <input id="AnswerQ4Opt2" type="radio" name="AnswerQ4Opt2" value="10" onChange={this.handleSelection}/><br/>10
                                                </td>
                                                <td width="12" align="center" className="ratinghead"><b>{i18next.t('Extremely likely')}</b></td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>   
                                <br/>

                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th colSpan="6" align="left">{i++}.	{i18next.t("What is the main reason you decided to purchase from Coke&Go?")}<br/><br/>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className=''>
                                                <input type="radio" name="AnswerQ5Opt1" value="It’s available 24/7" onChange={this.handleSelection} onClick={()=> this.clearTextArea("Yes","AnswerQ5Opt2")}/>
                                                <label  className='ml-2'>{i18next.t("It’s available 24/7")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=''>
                                                <input type="radio" id="AnswerQ5Opt1" name="AnswerQ5Opt1" value="Proximity to my location" onChange={this.handleSelection} onClick={()=> this.clearTextArea("Yes","AnswerQ5Opt2")}/>
                                                <label  className='ml-2'>{i18next.t("Proximity to my location")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=''>
                                                <input type="radio" id="AnswerQ5Opt1" name="AnswerQ5Opt1" value="Has the product I want" onChange={this.handleSelection} onClick={()=> this.clearTextArea("Yes","AnswerQ5Opt2")}/>
                                                <label  className='ml-2'>{i18next.t("Has the product I want")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=''>
                                                <input type="radio" id="AnswerQ5Opt1" name="AnswerQ5Opt1" value="I like to try new technology" onChange={this.handleSelection} onClick={()=> this.clearTextArea("Yes","AnswerQ5Opt2")}/>
                                                <label  className='ml-2'>{i18next.t("I like to try new technology")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=''>
                                                <input type="radio" id="AnswerQ5Opt1" name="AnswerQ5Opt1" value="Promotions or discounts" onChange={this.handleSelection} onClick={()=> this.clearTextArea("Yes","AnswerQ5Opt2")}/>
                                                <label  className='ml-2'>{i18next.t("Promotions or discounts")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="100%" className=''>
                                                <input type="radio" id="AnswerQ5Opt1" name="AnswerQ5Opt1" value="Other" onChange={this.handleSelection}/>
                                                    <label className='ml-2'>
                                                        {
                                                            (this.applicationTypeId == 12 || this.applicationTypeId == 24)
                                                                ?
                                                                i18next.t("Other Specify-1")
                                                                :
                                                                i18next.t("Other. Specify")
                                                        }
                                                    </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="100%">                                    
                                                <textarea className="textarea form-control" name='AnswerQ5Opt2' disabled={this.state.surveydata.AnswerQ5Opt1=="Other" ? false : true} onChange={this.handleSelection} value={surveydata.AnswerQ5Opt2}></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                    
                                <br/>
                                    
                                    
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="table_radio">
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" align="left"><b>{i++}. {i18next.t("Why did you purchase a drink?")}</b><br/><br/></td>
                                        </tr>                                
                                        <tr>
                                            <td className=''>
                                                <input type="checkbox" id="AnswerQ6Opt1" name="AnswerQ6Opt1" value="To get something to drink with a meal" onChange={this.handleSelection}/>
                                                <label  className='ml-2'>{i18next.t("To get something to drink with a meal")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=''>
                                                <input type="checkbox" id="AnswerQ6Opt2" name="AnswerQ6Opt2" value="To get something to drink on the go" onChange={this.handleSelection}/>
                                                <label  className='ml-2'>{i18next.t("To get something to drink on the go")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=''>
                                                <input type="checkbox" id="AnswerQ6Opt3" name="AnswerQ6Opt3" value="To buy and drink later (stock up)" onChange={this.handleSelection}/>
                                                <label  className='ml-2'>{i18next.t("To buy and drink later (stock up)")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=''>
                                                <input type="checkbox" id="AnswerQ6Opt4" name="AnswerQ6Opt4" value="Out of impulse" onChange={this.handleSelection}/>
                                                <label  className='ml-2'>{i18next.t("Out of impulse")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=''>
                                                <input type="checkbox" id="AnswerQ6Opt5" name="AnswerQ6Opt5" value="Other" onChange={this.handleSelection} onClick={(e)=> this.clearTextArea(!e.target.checked ? "Yes" : "","AnswerQ6Opt6")}/>
                                                    <label className='ml-2'>
                                                        {
                                                            (this.applicationTypeId == 12 || this.applicationTypeId == 24 || this.applicationTypeId == 32)
                                                                ?
                                                                i18next.t("Other Specify-2")
                                                                :
                                                                i18next.t("Other. Specify")
                                                        }
                                                    </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="100%"> 
                                                <textarea className="textarea form-control" name='AnswerQ6Opt6' disabled={this.state.surveydata.AnswerQ6Opt5=="Other" ? false : true} onChange={this.handleSelection} value={surveydata.AnswerQ6Opt6}></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                    
                                <br/>
                                    
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="table_radio">
                                    <tbody>
                                        <tr>
                                                <td colSpan="6" align="left"><b>{i++}. {
                                                        (this.applicationTypeId == 12 || this.applicationTypeId == 24 || this.applicationTypeId == 32)
                                                            ?
                                                            i18next.t("Had the Coke&Go machine not been there, what would you have done?")
                                                            :
                                                            i18next.t("Had the Coke&Go cooler not been there, what would you have done?")
                                                    }
                                                    </b><br /><br /></td>
                                        </tr>                                
                                        <tr>
                                            <td className=''>
                                                <input type="radio" id="AnswerQ7Opt1" name="AnswerQ7Opt1" value="I wouldn’t have bought anything" onChange={this.handleSelection} onClick={()=> this.clearTextArea("Yes","AnswerQ7Opt2")}/>
                                                <label  className='ml-2'>{i18next.t("I wouldn’t have bought anything")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className=''>
                                                <input type="radio" id="AnswerQ7Opt1" name="AnswerQ7Opt1" value="I would have gone to another store nearby" onChange={this.handleSelection} onClick={()=> this.clearTextArea("Yes","AnswerQ7Opt2")}/>
                                                <label  className='ml-2'>{i18next.t("I would have gone to another store nearby")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="radio" id="AnswerQ7Opt1" name="AnswerQ7Opt1" value="I would have ordered from a delivery service" onChange={this.handleSelection} onClick={()=> this.clearTextArea("Yes","AnswerQ7Opt2")}/>
                                                <label  className='ml-2'>{i18next.t("I would have ordered from a delivery service")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="radio" id="AnswerQ7Opt1" name="AnswerQ7Opt1" value="Other" onChange={this.handleSelection}/>
                                                    <label className='ml-2'>
                                                        {
                                                            (this.applicationTypeId == 12 || this.applicationTypeId == 24 || this.applicationTypeId == 32)
                                                                ?
                                                                i18next.t("Other Specify-3")
                                                                :
                                                                i18next.t("Other. Specify")
                                                        }
                                                    </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="100%">                                         
                                                <textarea className="textarea form-control" name='AnswerQ7Opt2' disabled={this.state.surveydata.AnswerQ7Opt1=="Other" ? false : true} onChange={this.handleSelection} value={surveydata.AnswerQ7Opt2}></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                    
                                <br/>                                
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="table_radio">
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" align="left"><b>{i++}. {i18next.t("Is there anything else you want to tell us about your experience with Coke&Go?")}</b></td>
                                        </tr>
                                        <tr>
                                            <td width="100%">                                         
                                                <textarea className="textarea form-control mt-2" name='AnswerQ8Opt1' onChange={this.handleSelection} value={surveydata.AnswerQ8Opt1}></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='text-center my-3'>
                                    <button type='submit' className='btn btn-large bg-white' disabled={this.state.isBtnDisable}>{i18next.t("Submit")}</button>
                                </div>
                            </form>
                        </div>
                    </>
                }   
                <div className="spinner" style={{display:this.state && this.state.isLoading ? 'block' : 'none'}}>
                    <div style={style}>
                    <ClipLoader
                        size={50}
                        color={"#000000"}
                        loading={this.state && this.state.isLoading ? true : false}
                    />
                    </div>
                </div>
            </>     
        )
    }
}

export default  Survey